import { ScheduleSettings } from '@triarc-labs/tl-planning-canvas';

export const commonEnvironment = {
  messageCountThrottleMin: 60000,
  messageCountThrottleMax: 120000,
  preventFormUnload: false,
  defaultAccentColor: 0x4273cb,
  defaultAccentColorStr: '#4273cb',
  sentry: undefined as string | undefined,
  useAkitaDevTools: false,
  defaultTenant: 3550,
  internalProjProjectClientIds: [1],
  updatePollingInterval: 60,
  updatePollingIntervalDelay: 10,
  keycloakCordovaAdapter: 'cordova',
  connectAppUrl: 'http://localhost:8000',
  redirectUrl: undefined as string | undefined,
  messageCenter: {
    counterRefreshTime: 30000,
    messageRefreshTime: 30000,
    perPage: 8,
  },
  bearerExcludedUrls: ['maps.googleapis.com'],
  planning: {},
  version: '17.0.51',
  authServerRealm: 'wwag',
  authServerClient: 'wwag-planning-client',
  tabletScreenSize: 1024,
  enableRealtimeConnection: true,
  hoursPerWorkingDay: 8.5,
  wettsteinScheduleSettings: {
    groupByPool: false,
    groupBackgroundColor: 0xdbfdff,
    poolGroupHeight: 28,
    progressBar: {
      plannedTaskColor: 0xff4149,
      provisionallyPlannedTaskColor: 0xaf75aa,
      unplannedLabourColor: 0xffd73a,
      freeCapacityColor: 0x65c94c,
      absenceColor: 0x434343,
      progressBarBackgroundColor: 0xffffff,
      progressBarLineHeight: 2,
      progressBarLinePaddingX: 6,
      progressBarLinePaddingY: 3,
    },
    defaultAccentColor: 0x4273cb,
    iconSizePx: {
      width: 8,
      height: 12,
    },
    planningObjectMinimalBreakingPointWidth: 100,
    showRowIndex: false,
    // the width of the task state at the beginning of the box
    planningObjectLabelWidth: 16,
    useDynamicProgressBarStart: false,
  },
  scheduleSettings: {
    defaultSettings: {
      hoursToPlan: 8.25,
    },
    assetsToLoad: [
      'assets/images/bubble_right.png',
      'assets/images/bubble_left.png',
      'assets/images/todos.png',
      'assets/images/fixed-appointment.png',
      'assets/images/fixed-appointment-n-todos.png',
      'assets/images/there-is-more.png',
    ],
    planningObjectFont: 'arial',
    sectionsPreloadDepth: 1.5,
    contextMenuWidth: 252,
    selectionPanelWidth: 0,
    sectionsViewPortDepth: 1,
    rowsBarWidth: 300,
    timeBarHeight: 50,
    planningObjectHeight: 46,
    planningObjectSeparatorHeight: 6,
    planningObjectCornerRadius: 3,
    planningObjectStubWidth: 30,
    minimumHourPixels: 500,
    minimumDayNamesPixels: 1000,
    minimumWeekNamesPixels: 1100,
    planningObjectClickRadius: 8,
    planningObjectDragDecoratorRadius: 8,
    planningObjectDragDecoratorColor: 0x999999,
    planningObjectDragDecoratorClickRadius: 14,
    planningObjectMinimumWidth: 50,
    planningObjectStartStopWidth: 32,
    planningObjectStartStopColor: 0x97d781,
    workHours: {
      from: 6,
      to: 19,
      enabled: true,
    },

    borderSpeedInitial: 8,
    borderSpeedMax: 60,
    borderSpeedInterval: 40,
    borderSpeedGrowth: 1.5,
    borderBottomRange: 150,
    inertiaTimeThreshold: 120,
    inertiaSpeedInterval: 35,
    inertiaSpeedFactor: 0.75,
    inertiaMinimumDistance: 20,
    linesColor: 0xe4e4e4,
    rowsBarLinesColorSelected: 0x3a3a3a,
    showScheduleCustomActions: false,
    scheduleHourScale: 10,
    scheduleDayScale: 2.6,
    scheduleWeekScale: 0.4,
    scheduleMonthScale: 0.1,
    maxScale: 15,
    minScale: 0.08,
    timeBarBackgroundColor: 0xe0e7ff,
    useWebGpu: false,
  } satisfies ScheduleSettings,
  taskSettings: {
    canAutoDisposition: false,
    showPositioningConfig: false,
    showTimeblockConfig: true,
    halfDayResolutionStrategry: true,
    stateColors: {
      planned: 0x919191,
      requestConfirmation: 0xbde4ff,
      confirmed: 0x74c5eb,
      rejected: 0xffd0d0,
      done: 0xd4d4d4,
      running: 0x97bf0d,
      paused: 0xc4db99,
    },
  },
  projectTaskGrid: {
    defaultSortField: 'startDate',
    defaultSortDirection: 'asc',
    priorityLevelColors: {
      trivial: 0x8ae080,
      low: 0x8ae080,
      medium: 0xfccd60,
      high: 0xff4747,
      urgent: 0xff4747,
    },
    stateColors: {
      created: 0xc9c9c9,
      partialPlanned: 0xccebf5,
      planned: 0x74c5eb,
      partialCompleted: 0x74c5eb,
      completed: 0xb8fcc0,
    },
  },
  agGridKey: '',
};
